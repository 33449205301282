import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.colors.background.lightest};
  border-radius: ${({ theme }) => theme.spacing(8)};
  padding: ${({ theme }) => theme.spacing(6)};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.tablet} {
    max-width: 860px;
    margin: ${({ theme }) => theme.spacing(2, 0)};
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
  @media ${({ theme }) => theme.tablet} {
    text-align: left;
    margin-left: ${({ theme }) => theme.spacing(10)};
  }
`;

export const Header = styled.h4`
  ${props => props.theme.typography.heading4}
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const BodyText = styled.p`
  margin: ${({ theme }) => theme.spacing(3, 0, 4)};
  ${({ theme }) => theme.typography.body}
`;

export const ImageWrapper = styled.div`
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  max-width: 295px;
  min-height: 212px;
  height: 30%;
  width: 100%;
  @media ${({ theme }) => theme.tablet} {
    max-width: 230px;
    min-height: 164px;
    height: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  max-height: 56px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  width: max-content;
  max-width: 100%;
  margin: auto;
  @media ${({ theme }) => theme.tablet} {
    margin: 0;
  }
`;

export const Highlight = styled.p`
  margin-top: ${({ theme }) => theme.spacing(6)};
  ${({ theme }) => theme.typography.subheading1};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.tertiary};

  @media ${({ theme }) => theme.tabletL} {
    margin: 0;
  }
`;
