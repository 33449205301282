'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'next/navigation';
import { CtaButton } from '@web/molecules';
import { BoxedCountdownClock } from '../BoxedCountdownClock';
import { useReporter } from '../../reporter';
import { MuxVideo } from '../MuxVideo';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import { orientationSettings } from './constants';
import { heroButtonClicked } from './events';
import {
  StyledHero,
  ContentContainer,
  Content,
  Header,
  Subheader,
  ButtonWrapper,
  InputWrapper,
  VideoWrapper,
  TextWrapper,
  EyebrowText,
  CountdownAndLink,
} from './styles.js';

export const VideoHero = ({
  eyebrowText,
  headline,
  headlineVariants,
  subheadline,
  cta,
  backgroundColor = 'secondary.chia',
  fontColor = 'text.lightest',
  horizontalOrientation = 'left',
  ctaColor,
  targetDate = null,
  video,
  level,
}) => {
  const reporter = useReporter();
  const searchParams = useSearchParams();
  const headlineVariant = searchParams?.get('hpkeyword');

  if (headlineVariant && headlineVariants) {
    const variant = headlineVariants.find(e => e.variant === headlineVariant);
    if (variant) {
      headline = variant.heading;
    }
  }

  return (
    <StyledHero backgroundColor={backgroundColor}>
      <ContentContainer
        horizontalOrientation={orientationSettings[horizontalOrientation]}
      >
        <Content
          horizontalOrientation={orientationSettings[horizontalOrientation]}
        >
          <TextWrapper>
            {eyebrowText && (
              <EyebrowText $fontColor={fontColor}>{eyebrowText}</EyebrowText>
            )}
            {headline && <Header $fontColor={fontColor}>{headline}</Header>}
            {subheadline && (
              <Subheader $fontColor={fontColor}>{subheadline}</Subheader>
            )}
            {(cta || targetDate) && (
              <CountdownAndLink>
                {cta && cta.component === 'email_capture' ? (
                  <InputWrapper>
                    <EmailCapture {...cta} buttonVariant={ctaColor} />
                  </InputWrapper>
                ) : (
                  cta && (
                    <ButtonWrapper backgroundColor={backgroundColor}>
                      <CtaButton
                        href={cta.target}
                        variant={ctaColor}
                        onClick={() =>
                          reporter.tag(heroButtonClicked(cta.text, cta.target))
                        }
                      >
                        {cta.text}
                      </CtaButton>
                    </ButtonWrapper>
                  )
                )}
                {targetDate && (
                  <BoxedCountdownClock
                    targetDate={targetDate}
                    textColor={fontColor}
                  />
                )}
              </CountdownAndLink>
            )}
          </TextWrapper>
        </Content>
        <VideoWrapper>
          <MuxVideo
            playbackId={video.playbackId}
            autoplay={video.autoplay}
            loop={video.loop}
            thumbnail={video.thumbnail}
          />
        </VideoWrapper>
      </ContentContainer>
    </StyledHero>
  );
};

VideoHero.displayName = 'Video Hero';

VideoHero.propTypes = {
  eyebrowText: PropTypes.string,
  headline: PropTypes.node,
  headlineVariants: PropTypes.array,
  subheadline: PropTypes.string,
  cta: PropTypes.object,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  image: PropTypes.object.isRequired,
  horizontalOrientation: PropTypes.oneOf(['left', 'center', 'right']),
  ctaColor: PropTypes.string,
  targetDate: PropTypes.instanceOf(Date), //eslint-disable-line react/forbid-prop-types,
  video: PropTypes.object.isRequired,
};
