'use client';
import styled from 'styled-components';

export const StyledBoxedDateTimeContainer = styled.div`
  margin-top: 1em;
  display: flex;
`;

export const StyledBoxContainer = styled.div`
  margin: auto;
  padding: ${props => props.theme.spacing(1)};
`;

export const StyledBoxTimeSlot = styled.div`
  border-radius: ${props => props.theme.spacing(4)};
  color: ${props =>
    props.$textColor && props.theme.colors.get(props.$textColor)};
  background: rgb(255 255 255 / 30%);
  font-size: ${props => props.theme.spacing(8)};
  text-align: center;
  padding: ${props => props.theme.spacing(5, 1)};
  width: ${props => props.theme.spacing(19)};
  @media ${({ theme }) => theme.tablet} {
    padding: ${props => props.theme.spacing(7, 4)};
    width: ${props => props.theme.spacing(24)};
  }
`;

export const StyledBoxTitleSlot = styled.h5`
  text-align: center;
  font-size: 14px;
  margin: ${props => props.theme.spacing(1)};
  padding: ${props => props.theme.spacing(1)};
  color: ${props =>
    props.$textColor && props.theme.colors.get(props.$textColor)};
`;
